
<template>
    <!-- mobile -->
    <template v-if="isMobile">
        <!-- card -->
        <div :class="{ 'bg-white border': !inline }" style="border-radius: 0.5rem;">
            <!-- header -->
            <div
                class="d-flex justify-content-between"
                @click="if (!inline) { show = !show; }"
            >
                <div class="flex-grow-1" :class="{ 'p-3': !inline }">
                    <slot name="header">
                        <div class="fw-medium">{{ props.caption }}</div>
                    </slot>
                </div>
                <template v-if="!inline">
                    <div class="flex-shrink-0 p-3">
                        <div :style="{ 'transition': 'all 500ms ease', 'transform': `rotate(${show ? 180 : 0}deg)` }">
                            <i class="bi bi-chevron-down" />
                        </div>
                    </div>
                </template>
            </div>
            <OCollapse v-model="show">
                <div class="d-flex flex-column gap-2" :style="inline ? {  } : { 'margin-top': '-0.75rem', 'padding': '0.5rem 0.75rem 0.75rem 0.75rem' }">
                    <slot />
                </div>
            </OCollapse>
        </div>
    </template>

    <!-- desktop -->
    <template v-else>
        <div>
            <div class="d-flex justify-content-start">
                <div
                    class="p-2 w-100 d-flex align-items-center bg-white pb-1 fw-medium"
                    v-if="props.caption" 
                    @click="show = !show" 
                    style="cursor: pointer;"
                >
                    <div class="flex-grow-1">
                        <slot name="header">
                            <span class="me-2">{{props.caption}}</span>
                        </slot>
                    </div>
                    <!-- chevron to show collapse state, but it didn't look all that good on desktop -->
                    <template v-if="!sharedObj?.hideGroupChevron">
                        <div class="flex-shrink-0 px-3">
                            <div :style="{ 'transition': 'all 500ms ease', 'transform': `rotate(${show ? 90 : 0}deg)` }">
                                <i class="bi bi-chevron-right" />
                            </div>
                        </div>
                    </template>
                </div>
                <slot name="links"></slot>
            </div>
            
            <OCollapse v-model="show">
                <div class="border-top" ref="propertiesItemWrapperRef">
                    <slot />
                </div>
            </OCollapse>
        </div>
    </template>
</template>

<script setup lang="ts">
import { ref, inject } from 'vue';
import { OCollapse } from "o365-ui-components";
import { InjectionKeys } from 'o365-utils';
import { isMobile } from "o365.GlobalState.ts";
import { userSession } from 'o365-modules';

export interface IProps {
    caption: string;
    collapsed?: any; // must be (any) to preserve undefined when prop is not provided
    inline?: boolean;
};

const props = defineProps<IProps>();

const propertiesItemWrapperRef = ref();

const sharedObj = inject(InjectionKeys.propertiesGridKey);
const propertiesGroup = sharedObj.value.usePropertiesGroup();

function getDefaultShow() {
    console.log("getDefaultShow", props.collapsed);
    if (props.collapsed != null) {
        return !props.collapsed;
    }
    if (isMobile.value) {
        return propertiesGroup.index === 0;
    }
    return true;
}

const show = ref<boolean>(getDefaultShow());
</script>

<style scoped>
    .mt-n075 {
        margin-top: -0.75rem;
    }
</style>
